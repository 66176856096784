.payslip {
    padding: 20px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 20px 2px #DDD;

    & tr td {
        padding: 8px;
    }

    & .editableRow {
        &:hover {
            background-color: rgba(0,200,220,0.1);
        }
      }
}