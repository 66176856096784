.content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;

    & .left {
        width: 500px;
        position: sticky;
        height: fit-content;
        top: 120px;
        margin-top: 20px;
    }

    & .simulator {
        margin: 20px;
        width: 800px;

        &>h4 {
            margin: 20px;
            font-size: x-large;
        }
    }

    .button-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
}