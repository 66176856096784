.navBar {
    position: fixed;
    background-color: #024B73 !important;
    border-bottom: '2px solid #ea580d';
    box-shadow: 'none';
  }

  a {
    text-decoration: none;
    color: white;
  }