.contentSimulator {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // Définit trois colonnes égales
    gap: 20px; // Espace entre les colonnes
    margin: 20px 0;

    & > .left {
        width: 100%;
        position: sticky;
        height: fit-content;
        top: 120px;
    }

    h5 {
        margin: 20px;
        font-size: x-large !important;
    }

    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr); // Deux colonnes pour les écrans moyens
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr; // Une colonne pour les petits écrans
    }
}

.simulator {
    height: 20%;

    & > .bodyEstimation {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.buttonSimulation {
    color: #e0672a !important;
    border: 1px solid #ea580d !important;
    border-radius: 50px !important;
    width: 50%;
    margin: 20px auto !important;
}

.buttonSimulation:hover {
    background-color: #e0672a !important;
    color: white !important;
}

.switchContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    & > .switchLabel {
        padding: 8px 16px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        border-bottom: none;
    }

    & > .switchLabelActive {
        background-color: #ea580d;
        color: #fff;
    }
}
