.form {
    padding: 2em;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 20px 2px #DDD;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;

    & h1 {
        margin-top: 2em;
    }

    & button {
        background-color: #e06a38;

        &:hover {
            background-color: #ea580d !important;
        }
    }
}